<template>
  <div>
    <welcome-conference-banner class='py-8' background-color='#FAFAFA'/>
    <div class='pt-8 px-2'>
      <button
        class='hidden lg:hidden w-full py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
        :style="`background-color: ${eventMainThemeColor};`"
        @click='goToCurrentSession'>
        입장하기
      </button>
    </div>
    <div class='relative pt-8 overflow-x-auto' :style='setupVariableColors'>
      <table class='w-full text-gray-900 text-xs lg:text-sm'>
        <thead>
          <tr>
            <th class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>시간</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
            <th class='border-b px-2 py-2 text-left'>강의제목</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
            <th class='border-b px-2 py-2 text-left w-1/3 lg:w-auto'>강사</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>08:20 - 08:30</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>개회사 및 인사말씀</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200 hidden'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
        </tbody>
        <tbody>
          <tr class='border-t border-gray-600 font-semibold' style='background-color:var(--lightColor);'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>08:30 - 12:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>오전 강의</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200 hidden'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>08:30 - 08:50</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell w-24'></td>
            <td class='border-b px-2 py-2'>당뇨병 교육자의 역할</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>김난희 <span class='block lg:inline-block lg:ml-1 opacity-70'>(고려대 안산병원 내분비내과)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>08:50 - 09:10</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>당뇨병의 진단, 분류 및 합병증</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>서지아 <span class='block lg:inline-block lg:ml-1 opacity-70'>(고려대 안산병원 내분비내과)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>09:10 - 09:40</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>당뇨병의 약물요법</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>30분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>정수진 <span class='block lg:inline-block lg:ml-1 opacity-70'>(세종병원 내분비내과)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>09:40 - 10:10</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>인슐린 및 주사제 치료</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>30분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이경애 <span class='block lg:inline-block lg:ml-1 opacity-70'>(전북대병원 내분비내과)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>10:10 - 10:20</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Break</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>10:20 - 10:50</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>인슐린 펌프, 연속혈당측정기</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>30분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>문선준 <span class='block lg:inline-block lg:ml-1 opacity-70'>(강북삼성병원 내분비내과)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>10:50 - 11:20</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>식사요법 이론 - 당뇨병 식사요법</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>30분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이지현 <span class='block lg:inline-block lg:ml-1 opacity-70'>(아주대병원 영양팀)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>11:20 - 12:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>운동요법 교육 - 이론</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>40분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>정승재 <span class='block lg:inline-block lg:ml-1 opacity-70'>(삼성서울병원 스포츠의학실)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>12:00 - 12:40</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>점심식사</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'>40분</td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b border-gray-600 px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
        <tbody>
          <tr class='border-t border-gray-600 font-semibold' style='background-color:var(--lightColor);'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>12:40 - 19:10</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>오후 실습</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200 hidden'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>12:40 - 13:40</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>행동변화 및 실습</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>60분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이지현 <span class='block lg:inline-block lg:ml-1 opacity-70'>(강남세브란스병원 사회사업팀)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>13:40 - 14:20</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>식사요법 실습 - 식사일기 분석 및 평가</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>40분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>조재원 <span class='block lg:inline-block lg:ml-1 opacity-70'>(삼성서울병원 영양팀)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>14:20 - 14:30</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Break</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>14:30 - 15:10</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>식사요법 실습 - 실제적인 탄수화물 계산법</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>40분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>권미라 <span class='block lg:inline-block lg:ml-1 opacity-70'>(서울대병원 급식영양과)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>15:10 - 15:50</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>운동요법 교육 - 실습</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>40분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이혜영 <span class='block lg:inline-block lg:ml-1 opacity-70'>(서울아산병원 스포츠건강의학센터)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>15:50 - 16:50</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>인슐린 주사법 및 실습</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>60분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>차혜정 <span class='block lg:inline-block lg:ml-1 opacity-70'>(부천성모병원 간호부)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>16:50 - 17:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Break</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>17:00 - 18:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>혈당측정 및 저혈당 관리</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>60분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>장연정 <span class='block lg:inline-block lg:ml-1 opacity-70'>(세브란스병원 간호과)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>18:00 - 18:40</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>발관리 및 실습</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>40분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>나영 <span class='block lg:inline-block lg:ml-1 opacity-70'>(여의도성모병원 간호팀)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>18:40 - 19:10</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>효과적인 교육기법</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'>30분</td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'>윤보영 <span class='block lg:inline-block lg:ml-1 opacity-70'>(일산백병원 류마티스내과)</span></td>
            <th class='border-b border-gray-600 px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>19:10 - 19:20</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>폐회</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b border-gray-600 px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200 hidden'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'

export default {
  name: 'Kda2022CustomProgram',
  components: {
    WelcomeConferenceBanner,
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'colorsInRange',
    ]),
    setupVariableColors () {
      return {
       '--lightestColor': this.colorsInRange[0],
       '--lightColor': this.colorsInRange[1],
       '--inputColor': this.colorsInRange[2],
       '--darkColor': this.colorsInRange[3], 
      }
    },
  },
  methods: {
    goToCurrentSession () {
      this.$router.push({name: 'CurrentSession'}).catch(() => {})
    }
  }
}
</script>
